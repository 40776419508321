import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import Overlay from 'components/shared/overlay';
import SiteLayout, { SiteLayoutProps } from './site-layout';

const AuthLayoutContent = dynamic(() => import('./auth-layout-content'), {
  ssr: false,
  loading: () => <Overlay />,
});

export interface AuthLayoutProps extends SiteLayoutProps {
  redirectOnAuthenticated?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = (props) => {
  return (
    <SiteLayout {...props}>
      <AuthLayoutContent {...props} />
    </SiteLayout>
  );
};

export default observer(AuthLayout);
