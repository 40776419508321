import { Flex, FlexProps, Spinner } from '@chakra-ui/react';
import React, { FC } from 'react';

const Overlay: FC<FlexProps> = (props) => {
  return (
    <Flex
      className="overlay"
      bg="white"
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      h="100vh"
      zIndex="overlay"
      align="center"
      justify="center"
      {...props}
    >
      <Spinner speed="0.6s" />
    </Flex>
  );
};

export default Overlay;
